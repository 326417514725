import React from 'react';
import { IoLogoFacebook } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io5";
import { BsTwitterX } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-4">
        {/* Column 1 */}
        {/* <div className="text-center">
          <img src='./Images/VILA_Campus_Title.png' alt="VILA Campus" className='mb-5' />
          <p>VILA Campus is a product development company. We are a team of experienced professionals who are skilled in modern technologies to provide end-to-end product development services.</p>
          <div className='flex justify-center space-x-4 mt-5'>
            <div className="bg-blue-500 rounded-full p-2">
              <IoLogoFacebook className="text-white text-2xl" />
            </div>
            <div className="bg-red-500 rounded-full p-2">
              <IoLogoInstagram className="text-white text-2xl" />
            </div>
            <div className="bg-green-500 rounded-full p-2">
              <BsTwitterX className="text-white text-2xl" />

            </div>
          </div>

        </div> */}
        {/* Column 2 */}
        <div className="text-center">
          <h4 className="font-bold mb-4 text-2xl">Useful Links</h4>
          <div className="flex justify-center">
            <ul className="list-none p-0">
              <li className="flex items-start mb-3">
                <FaArrowRightLong className="mr-2 text-blue-500 w-6" />
              <Link to='/'><span className="text-lg font-semibold">Home</span></Link>  
              </li>
              <li className="flex items-start mb-3">
                <FaArrowRightLong className="mr-2 text-blue-500 w-6" />
                <Link to='/About'>  <span className="text-lg font-semibold">About</span></Link>
              </li>
              <li className="flex items-start mb-3">
                <FaArrowRightLong className="mr-2 text-blue-500 w-6" />
                <Link to='/Career'> <span className="text-lg font-semibold">Career</span></Link>
              </li>
              <li className="flex items-start mb-3">
                <FaArrowRightLong className="mr-2 text-blue-500 w-6" />
                <Link to='/Contact'> <span className="text-lg font-semibold">Contact</span></Link>
              </li>
              <li className="flex items-start mb-3">
                <FaArrowRightLong className="mr-2 text-blue-500 w-6" />
                <Link to='/Product'><span className="text-lg font-semibold">Product</span></Link>  
              </li>

            </ul>
          </div>

        </div>

        {/* Column 3 */}
        <div className="text-center">
          <h4 className="font-bold mb-4 text-2xl">Contact Us</h4>
          <div className="flex justify-center">
            <ul className="list-none p-0 ">
              {/* <li className="flex items-start mb-3">
                <IoLocationSharp className="text-2xl text-white rounded-full bg-blue-500 p-2" style={{ fontSize: '2.5rem' }} />
                <div className="ml-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <span className="block font-semibold text-xl">Our Address</span>
                  <span className='text-sm text-gray-400 mr-9'>VILA Campus LLP # 245, Manikonda, Hyderabad, India</span>
                </div>
              </li> */}

              <li className="flex items-start mb-3">
                <MdOutlineMarkEmailUnread className="text-2xl text-white rounded-full bg-blue-500 p-2" style={{ fontSize: '2.5rem' }} />
                <div>
                  <span className="block font-semibold text-xl">Our Email</span>
                  <span className='text-sm text-gray-400'>info@vilacampus.com<br />vilacampus@gmail.com</span>
                </div>
              </li>
              <li className="flex items-start mb-3">
                <FaPhoneAlt className="text-2xl text-white rounded-full bg-blue-500 p-2" style={{ fontSize: '2.5rem' }} />
                <div>
                  <span className="block font-semibold text-xl">Our Phone</span>
                  <span className='text-sm text-gray-400'>+91 95055 99969<br />+91 88973 39489 </span>
                </div>
              </li>
            </ul>

          </div>

        </div>

        {/* Column 4 */}
        <div className="text-center">
          <h4 className="font-bold mb-4  text-2xl">Social Media</h4>
          <div className='flex justify-center space-x-4 mt-5'>
            <div className="bg-blue-500 rounded-full p-2">
              <IoLogoFacebook className="text-white text-2xl" />
            </div>
            <div className="bg-red-500 rounded-full p-2">
              <IoLogoInstagram className="text-white text-2xl" />
            </div>
            
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-8 mx-4">
        <div>
          <hr className="border-t border2 border-red-500" />
        </div>
        <div className="text-left">
          <p className="text-sm">&copy; 2024 vilacampus. All rights reserved.</p>
        </div>
        <div className="text-right px-20">
          <p className="text-sm"><Link to='/Terms' className='mr-2'>Terms &amp; Conditions </Link> | <Link to='/Privacy'className='ml-2'>Privacy Policy</Link></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
