import React from 'react';

const OurLatestProject = () => {
    return (
        <div className='container mx-auto my-auto'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 p-5">
                {/* Column 1 */}
                <div className="col-span-1 lg:mt-20">
                    <p className='text-blue-700 font-semibold mt-10 text-4xl'>LEARNING</p>
                    <p className='text-4xl font-bold mt-3'>WITH MODERN TECHNOLOGY</p>
                    <p className='mt-3 font-semibold'>Learning every kid in their quest for knowledge.</p>
                    <p className='mt-3'>VILA Campus is an innovative App and software development company that is specialized in building custom software applications that are well tailored to fit your business needs. However, our vision is to provide total transparent cooperation and work excellence. The professionals here work dedicatedly and in a structured way by diving into the understanding process of what the client needs.</p>
                </div>

                {/* Column 2 */}
                <div className="col-span-1 m-5">
                    <img src='./Images/Learnmordern.avif' />
                </div>
            </div>
        </div>
    );
};

export default OurLatestProject;
