import React from 'react';
import OurServices from './OurServices';
import Testimonial from './Testmonial';
import Callback from './Callback';
import Yourbusiness from './Yourbusiness';
import OurMission from './OurMission';
import OurLatestProject from './OurLatestProect';


const Headpage = () => {
  return (
    <div>
      <div className="min-h-screen flex bg-cover bg-center rounded-[20px] m-2" style={{backgroundImage: "url('./Images/Home-mainhead.jpg')"}}>
        <div className=" inset-0 flex items-center justify-center">
          <div className="text-white  p-4 md:p-8 rounded-lg max-w-md text-center">
          <h1 className="text-4xl md:text-6xl lg:text-3xl font-bold mb-2 md:mb-4">Welcome to VILA Campus</h1>
            <h1 className="text-lg md:text-xl lg:text-xl font-bold mb-2 md:mb-4">Software Solutions For Your Business</h1>
            <p>We build software with strategy, design, & user experience that drive excellent results</p>
            <div className='text-center mt-4 md:mt-5'>
              <button className='font-bold px-4 md:px-5 py-2 md:py-3 bg-blue-400 rounded-lg'>Get a Quote</button>
            </div>
          </div>
        </div>
      </div>
      <OurServices />
      <Testimonial />
      <Callback />
      <Yourbusiness />
      <OurMission />
      <OurLatestProject />
     
    </div>
  );
};

export default Headpage;
