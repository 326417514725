import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdWifiCalling } from "react-icons/md";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white border-gray-200">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to='/'  className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="./Images/VILA_Campus_Title.png" className="h-8" alt="Logo" />
          {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
        </Link>
        <button
          onClick={toggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700"
          aria-expanded={isOpen ? "true" : "false"}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg>
        </button>
        <div className={`w-full md:block md:w-auto ${isOpen ? '' : 'hidden'}`} id="navbar-default">

          <ul className='font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white md:text-blue-500 dark:border-gray-700"'>
          <NavLink
                    to="/"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                >
                    HOME
                </NavLink>  
                <NavLink
                    to="/About"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                >
                   About
                </NavLink>    
                <NavLink
                    to="/Product"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                >
                   Products
                </NavLink>    
                <NavLink
                    to="/Career"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                >
                  Career
                </NavLink>    
                <NavLink
                    to="/Contact"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                >
                 Contact Us
                </NavLink>  
                  {/* <Link to='/' className="block lg:inline-block hover:text-gray-300">Home</Link> */}
            {/* <Link to='/About' className="block lg:inline-block hover:text-gray-300">About</Link> */}
            {/* <Link to='/Product' className="block lg:inline-block hover:text-gray-300">Products</Link> */}
            {/* <Link to="/Career" className="block lg:inline-block hover:text-gray-300">Career</Link> */}
            {/* <Link to="/Contact" className="block lg:inline-block hover:text-gray-300">Contact Us</Link> */}
          </ul>
        </div>
        <button className="hidden lg:block px-5 py-3 rounded bg-[#ECF1F5] text-[#1D2C38] hover:bg-blue-600 hover:text-white">
          <div className='flex ' style={{ fontWeight: '400' }}><MdWifiCalling className='mr-2 mt-1' />Call Us: 9505599969</div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
