import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaWhatsapp } from 'react-icons/fa';
import config from '../../appconfig';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });
  const [isVerified, setIsVerified] = useState(false);

  let verifyTimeout;

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => {
      if (verifyTimeout) clearTimeout(verifyTimeout);
    };
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleVerify = () => {
    setIsVerified(true);
    
    if (verifyTimeout) clearTimeout(verifyTimeout);

    verifyTimeout = setTimeout(() => {
      setIsVerified(false);
    }, 60*1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!isVerified){
      alert("Please Verify the Recapcha")
      return;
    }
    try {
      // Send form data to your API endpoint
      const response = await fetch(config.baseUrl + 'notification/sendVisitorNotification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        // Clear form fields after successful submission
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: ''
        });
        console.log('Form submitted successfully');
        console.log('Submitted data:', formData);
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div>
      <div className="min-h-80 flex bg-cover bg-center " style={{ backgroundImage: "url('./Images/Contact-main-baner.avif')" }}>
        <div className=" inset-0 flex items-center justify-center">
          <div className="text-white  p-4 md:p-8 rounded-lg max-w-md text-center">
            <h1 className="text-6xl font-bold">Contact Us</h1>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-10">
        <div className="text-center">
          <p className="font-bold text-4xl">
            Get a Free Quote
          </p>
          <p className="mt-5">
            VILA Campus gives you the tools you need to optimize your digital
            experience, to make sure we give you the most relevant information
            about our products and pricing. Get customized for your specific
            needs. Please contact us to request your free demo, and one of our
            product experts will get in touch with you shortly.
          </p>
        </div>

        
        <div className="flex flex-col md:flex-row items-stretch w-full max-w-full mt-30 relative bg-cover bg-center p-5 mt-10 mb-10" style={{ backgroundImage: 'url("./Images/Contact-imgbg.png")', backgroundColor: '#17305c' }}>
          <div className="brxe-block bg-bricks-bg-dark p-4 flex-grow md:flex-grow-0 md:w-1/3">
            <div className="flex flex-col items-center">
              <div className="rounded-full bg-white p-2 inline-block mb-2">
                <img src='./Images/Contact-Location.png' alt='Contact-Img-1' className="rounded-full filter brightness-110 saturate-150 hue-rotate-180 text-385b98 w-12 h-12" />
              </div>
              <div className="text-center text-white mt-2">
                <h3 className="text-2xl font-bold">Address</h3>
                <p>VILA Campus LLP, #245, Manikonda, Hyderabad.</p>
              </div>
            </div>
          </div>
          <div className="brxe-block bg-bricks-bg-dark p-4 flex-grow md:flex-grow-0 md:w-1/3">
            <div className="flex flex-col items-center">
              <div className="rounded-full bg-white p-2 inline-block mb-2">
                <img src='./Images/Contact-Email.png' alt='Contact-Img-1' className="rounded-full filter brightness-110 saturate-150 hue-rotate-180 text-385b98 w-12 h-12" />
              </div>
              <div className="text-center text-white mt-2">
                <h3 className="text-2xl font-bold">Email</h3>
                <a className="text-white mt-2" href="mailto:vilacampus@gmail.com">vilacampus@gmail.com</a><br />
                <a className="text-white mt-2" href="mailto:info@vilacampus.com">info@vilacampus.com</a>
              </div>

            </div>
          </div>

          <div className="brxe-block bg-bricks-bg-dark p-4 flex-grow md:flex-grow-0 md:w-1/3">
            <div className="flex flex-col items-center">
              <div className="rounded-full bg-white p-2 inline-block mb-2">
                <img src='./Images/Contact-Call.png' alt='Contact-Img-1' className="rounded-full filter brightness-110 saturate-150 hue-rotate-180 text-385b98 w-12 h-12" />
              </div>
              <div className="text-center text-white mt-2">
                <h3 className="text-2xl font-bold">Mobile</h3>
                <a className="text-white mt-2" href="tel:+91 9505599969">+91 95055 99969</a>
                <br />
                <a className="text-white mt-2" href="tel:+918897339489">+91 88973 39489</a>
                <br />
                <a className="text-white mt-2 flex items-center" href="tel:+919505599969">
                  <FaWhatsapp className="mr-2" />
                  +91 95055 99969
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/5 p-4">
          <img src='./Images/Contact-beside-form.avif' alt='Contact-img' className="w-full" />
        </div>
        <div className="w-full md:w-3/5 p-4">
          <section id="" className="brxe-section bg-blue-100 py-12 ">
            <div id="brxe-tnkvxy" className="brxe-container mx-auto max-w-xl px-4 rounded-lg">
              <h2 id="brxe-kchnka" className="text-center text-3xl font-bold mb-8">Please Send Your Requirements</h2>
              <form onSubmit={handleSubmit} className="brxe-form flex flex-col space-y-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name *"
                    required
                    className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary w-full hover:border-blue-500"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone *"
                    required
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-primary w-full hover:border-blue-500"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-primary w-full hover:border-blue-500"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message *"
                    required
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-primary w-full h-40 resize-none hover:border-blue-500"
                  ></textarea>
                </div>
                <div className="form-group">
                <ReCAPTCHA
            sitekey={config.RECAPCHASITEKEY}
            size="normal"
            onChange={handleVerify} 
          /> 
                </div>
                <div className="form-group submit-button-wrapper">
                  <button type="submit" className="px-6 py-3 bg-blue-600 font-bold text-white rounded-md focus:outline-none flex items-center justify-center w-full">
                    <span className="text-center">Send</span>
                    <i className="fas fa-arrow-right ml-2"></i>
                    <span className="loading">
                      <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="w-6 h-3 fill-current text-white">
                      
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
