// Testimonial.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonial = () => {
    const testimonials = [
        {
            id: 1,
            comment: 'TECHNOLOGY ',
        },
        {
            id: 2,
            comment: 'INNOVATE',
        },
        // Add more testimonials as needed
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768, // Adjust as needed
                settings: {
                    slidesToShow:1,
                },
            },
            {
                breakpoint: 1024, // Adjust as needed
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1280, // Adjust as needed
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div>
            <Slider {...settings} className='mt-10 mb-10'>
                {testimonials.map(testimonial => (
                    <div key={testimonial.id}>
                        <div className=" ">
                            <div className="flex items-center mb-4">
                                <div>
                                    <p className="text-blue-800 font-bold text-5xl leading-relaxed">{testimonial.comment}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Testimonial;
