import React from 'react';

const Callback = () => {
  return (
   <div>
    <div className="min-h-screen flex bg-cover bg-center " style={{backgroundImage: "url('./Images/Home-Aicallback.avif')"}}>
        <div className=" inset-0 flex items-center justify-center">
          <div className="text-black  p-4 md:p-8 rounded-lg  max-w-md text-center">
            <h1 className="text-4xl md:text-3xl lg:text-3xl font-bold mb-2 md:mb-4">We deal with the aspects of professional IT Services</h1>
            <h1 className="text-lg md:text-xl lg:text-2xl font-bold mb-2 md:mb-4">AI Technology </h1>
          
          </div>
        </div>
      </div>
   </div>
  );
};

export default Callback;
