import React from 'react'

const Yourbusiness = () => {
    return (
        <div>
            <div className='container mt-10'>
                <p className='text-center text-blue-500'> OUR LATEST PROJECTS</p>
                <p className='text-center text-5xl font-bold mt-5'>Custom IT Solutions for<br /> your business</p>
            </div>
            <div className="py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                        {/* Card 1 */}
                        <div className="hover:bg-blue-200 rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                            <div className="px-6 py-8">
                            <div className='flex justify-center'>
                                    <img src='./Images/ERP.png' />
                                </div>                                <p className="text-center text-blue-500 text-lg font-semibold mb-4">Education ERP Software</p>
                                <h1 className="  mb-6">Student management software for schools and collges </h1>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div className="hover:bg-blue-200 rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                            <div className="px-6 py-8 ">
                                <div className='flex justify-center'>
                                    <img src='./Images/GPS Yourbusiness.png' />
                                </div>
                                <p className="text-center text-blue-500 text-lg font-semibold ">GPS Tracking</p>
                                <h1 className="  mb-6">location based GPS Tracking for vechels</h1>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div className="hover:bg-blue-200 rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                            <div className="px-6 py-8">
                                <div className='flex justify-center'>
                                    <img src='./Images/E-cormmereceYours.png' />
                                </div>
                                <p className="text-center text-blue-500 text-lg font-semibold mb-4">E-commerce</p>
                                <h1 className="  mb-6">online shping portel for multiple venders and multipule products</h1>
                            </div>
                        </div>

                        {/* Card 4 */}
                        <div className="hover:bg-blue-200 rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
                            <div className="px-6 py-8">
                            <div className='flex justify-center'>
                                    <img src='./Images/Empolyment Yoursbusiness.png' />
                                </div>                                
                                <p className="text-center text-blue-500 text-lg font-semibold mb-4">Training And Empolyment </p>
                                <h1 className="  mb-6">Training for sutends and empolyment for job seekrs</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Yourbusiness
