import React from 'react';

const OurMission = () => {
  return (
    <div className="container mx-auto mt-8 flex flex-wrap">
      <div className="w-full md:w-1/2 p-4">
        <div
          className="bg-cover bg-center h-64 md:h-auto relative"
          style={{
            backgroundImage: 'linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url("./Images/Ourmission.jpg")',
            backgroundSize: 'cover',
            position: 'relative',
            paddingTop: '2em',
            paddingRight: '2em',
            paddingBottom: '2em',
            paddingLeft: '2em',
            
          }}
        >
          <div className="h-full flex items-center justify-center">
            <div className="">
              <h2 className="text-white text-2xl font-bold mb-4">Our Mission </h2>
              <p className="text-white">Develop software to minimize human efforts, so we can focus more on living and less on working.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-4">
        <div
          className="bg-cover bg-center h-64 md:h-auto relative"
          style={{
            position: 'relative',
            paddingTop: '2em',
            paddingRight: '2em',
            paddingBottom: '2em',
            paddingLeft: '2em',
            backgroundColor: '#2b1027',
            backgroundImage: 'url("./Images/Ourvission.png"), linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
            backgroundSize: 'contain, cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right',
          }}
        >
          <div className="h-full flex items-center justify-center rounded-lg mt-5">
            <div className="">
              <h2 className="text-white text-2xl font-bold mb-4">Our Vision</h2>
              <p className="text-white">Build the best in class software product and quality service.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
